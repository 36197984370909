import "../styles/radio.css";

const Radio = ({ options, title, name, selectedName, setName }) => {
  return (
    <div className="radio-block">
      <h3>{title}</h3>
      <div>
        {options.map((opt) => (
          <Label
            sharedName={name}
            opt={opt}
            key={opt.name}
            selectedName={selectedName}
            setName={setName}
          />
        ))}
      </div>
    </div>
  );
};

const Label = ({
  sharedName,
  selectedName,
  setName,
  opt: { name, title, desc, price },
}) => (
  <label className={`radio-label ${selectedName === name ? "selected" : ""}`}>
    <input
      type="radio"
      name={sharedName}
      value={name}
      checked={selectedName === name}
      onChange={() => {
        setName(name);
      }}
    />
    <div>
      <p className="radio-tit">{title}</p>
      <p className="radio-desc">{desc}</p>
    </div>
    <p className="radio-price">{priceString(price)}</p>
  </label>
);

const priceString = (price) => `€${price.toFixed(2)}`;

export default Radio;
