import "../styles/checkbox.css";
import "../styles/option.css";
import Sep from "../imgs/sep.svg";
import meno from "../imgs/meno";
import piu from "../imgs/piu";

const Extra = ({ title, options, selected, handleChange }) => {
  return (
    <div className="checkbox-block" style={{ paddingBottom: "20px" }}>
      <img src={Sep} alt="" />
      <div className="tit-headers">
        <div>
          <h3>{title}</h3>
        </div>
      </div>
      <div className="opts">
        {options.map((opt, idx) => (
          <Label
            key={opt.name}
            opt={opt}
            qt={selected[idx]}
            add={(e) => {
              e.preventDefault();
              handleChange(idx, true);
            }}
            sub={(e) => {
              e.preventDefault();
              handleChange(idx, false);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Label = ({ opt, qt, add, sub }) => (
  <div
    style={{ padding: "20px", width: "30%", flex: "1 1 auto" }}
    className="crea-ingred"
  >
    <p style={{ marginBottom: "10px", textAlign: "center" }}>
      {opt.name} - <b>{priceString(opt.price)}</b>
      <br />
      {opt.type && `(conta come ${opt.type})`}
    </p>
    <div className="opt-qt" style={{ justifyContent: "center" }}>
      <button onClick={sub} disabled={qt === 0}>
        {meno}
      </button>
      <p>{qt}</p>
      <button onClick={add} disabled={qt === 5}>
        {piu}
      </button>
    </div>
  </div>
);

const priceString = (price) => `€${price.toFixed(2)}`;

export default Extra;
