const poetto = (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    className="poetto"
    viewBox="0 0 841.9 595.3"
  >
    <g>
      <path
        d="M410.2,411.3c-13.8-43-27.5-86.1-41.5-129.8c-2,0.9-3.7,1.5-5.2,2.4c-10.9,6-19.5,14.4-25.7,25.2
		c-3.8,6.6-6.2,7.4-12.9,4.3c-31-13.9-68.1-2.2-85.4,26.9c-0.4,0.7-0.7,1.7-1.3,2.1c-2.2,1.5-4.7,4-6.8,3.8
		c-2.1-0.2-4.9-2.9-5.8-5.2c-31.6-78.4,6.1-170.6,83.5-204.5c3.8-1.7,7.6-3.3,11.8-5.1c-1.6-5.1-3.2-10-4.7-14.8
		c-1.7-5.3-0.4-9,3.6-10.5c4.3-1.5,7.6,0.7,9.4,6.4c1.5,4.6,3,9.1,4.6,14.1c5.6-1.2,11.1-2.5,16.6-3.4c83.8-14,162.7,40,181.7,119
		c0.6,2.6-0.2,6.9-2,8.3c-1.9,1.5-6.2,1.4-8.7,0.4c-33.4-14.2-67.8-3-86.3,28.3c-3.1,5.2-5.3,5.9-11.6,2.9
		c-11-5.2-22.5-7.1-34.5-6.2c-1.2,0.1-2.3,0.3-3.5,0.5c-1.1,0.2-2.2,0.6-3.8,1.1c2.6,8.2,5,16.3,7.6,24.4
		c11.9,37.4,23.9,74.8,35.8,112.3c2,6.2,2.8,6.9,9.3,5.1c3.5-1,6.9-2.6,9.9-4.5c19-12.5,33.2-12.8,51.8-0.3
		c11.6,7.7,22.5,6.6,33.4-1c9.4-6.6,19.7-10.3,31.1-7.4c5.9,1.5,11.7,4.1,16.8,7.3c14.1,8.8,22.5,9,36.2-0.7
		c6.2-4.4,12.8-6.9,20.4-7.4c6.4-0.4,9.5,2.2,9.5,8.6c0.1,32.3,0.1,64.7,0,97c0,6.3-1.8,7.3-7.9,8.5c-4.9,0.9-10.1,2.6-14.3,5.3
		c-19.3,12.6-32.4,13-51.7,0.1c-10.9-7.3-21.4-6.9-32.1,0.2c-4.3,2.8-8.9,5.4-13.6,7.2c-11.4,4.2-22.9,2.7-32.4-4.3
		c-14.5-10.8-27.5-10-42,0.1c-13.2,9.3-28.3,9-41.3-0.3c-14.5-10.4-27.2-9.9-41.6,0.2c-13,9.1-27.9,9.4-40.9,0.2
		c-14.8-10.5-27.7-10.2-42.5-0.2c-14.2,9.6-29.5,8.1-43.6-1.4c-5.9-4-11.9-7.2-19.3-7.3c-4.1-0.1-5.7-3.1-5.7-6.9
		c-0.1-5.8-0.1-11.7-0.1-17.5c0-26.5,0-53,0-79.5c0-8.6,2.3-10.5,10.9-9.7c7.4,0.6,13.8,3.5,19.7,7.8c12.8,9.3,21.5,9.4,34.8,0.5
		c16.9-11.4,31.9-11.6,48.7,0c10.8,7.4,21.5,8.7,32.5,1.2C376.6,404.2,392.5,401.6,410.2,411.3z M228.3,505.3
		c7.3,4.1,13.8,7.6,20.1,11.5c8.3,5.2,16.8,6.1,25.6,1.6c2.5-1.3,4.9-2.9,7.2-4.4c15.4-10.2,31.1-10.6,46.2,0.1
		c12.6,9,24.4,8.9,36.8,0.1c8.4-6,18.1-9.2,28.3-7c7,1.5,14,4.5,20,8.3c11,7,21.4,7.3,32.2,0.3c2.5-1.6,5-3.3,7.6-4.8
		c10.8-6.1,21.7-6.9,32.9-1c3.5,1.9,7.1,3.7,10.5,5.8c11.8,7.4,23,6.8,34.4-1.3c4.9-3.5,10.9-5.9,16.7-7.3
		c11.8-2.9,22.3,1.3,31.9,8.1c10.6,7.6,21.2,7.5,32.1,0.6c5.9-3.7,12.1-6.7,18.7-10.3c0-10.8,0-22.6,0-35.5c-3.9,2.3-7.2,3.8-10.1,6
		c-14.7,10.8-29.7,11.5-45,1.2c-1.4-0.9-2.9-1.6-4.3-2.5c-11.6-7.2-22.8-7-34,1.2c-8.9,6.5-18.8,10.8-29.9,7.7
		c-6.5-1.8-12.7-5-18.6-8.4c-12.2-7.2-23.5-7.3-35.4,1.2c-15.2,10.9-31.3,10.8-46.5,0c-12.7-9-24.3-8.4-36.9,0.1
		c-5.5,3.7-12.3,6-18.8,7.3c-10.7,2.1-20.3-2.1-29-8.3c-10.2-7.2-20.6-7.5-31.4-1.4c-3.8,2.2-7.5,4.3-11.3,6.3
		c-11,5.9-22,5.7-32.9-0.4c-4.1-2.3-7.9-4.9-12-7.2c-1.5-0.9-3.1-1.5-5.2-2.4C228.3,483.1,228.3,494.7,228.3,505.3z M629.7,419.9
		c-4,2.3-7.1,3.8-9.9,5.8c-16.7,11.6-33.1,11.2-49.7-0.3c-3.4-2.3-7.3-4.1-11.2-5.2c-9-2.5-16.5,1.6-23.8,6.4
		c-11.9,8-24.5,10.2-37.9,4.4c-3.9-1.7-7.6-4.1-11.2-6.4c-8.8-5.8-17.9-6.7-27.4-1.9c-3.7,1.9-7.4,3.8-11,5.9
		c-14.4,8.5-28.4,7.7-41.6-2c-12.7-9.3-24.6-8.7-37.2,0.2c-15,10.5-30.8,10.4-45.7-0.1c-12.6-8.9-24.2-8.8-36.9-0.4
		c-17.2,11.4-33,10.4-49.6-1.8c-2.3-1.7-5-2.9-8.2-4.8c0,13.1,0,25,0,36.1c5.7,2.8,11.3,4.7,15.9,8c13.8,9.6,22,10,36.1,0.6
		c16.4-10.9,31.3-11.3,47.4-0.2c11.2,7.7,22,8.2,33.6,1.5c4.6-2.7,9.4-5,14.3-7.2c8.4-3.6,16.9-3.7,25.2,0.1c4.5,2.1,8.8,4.7,13,7.4
		c9.7,6.3,19.3,6.4,29.2,0.5c1.6-0.9,3.1-2,4.6-2.9c15.6-9.7,31.3-10.2,46.3,0.5c12.5,8.9,24.1,8.4,36.5-0.1
		c4.6-3.1,10-5.5,15.3-6.9c12.2-3.3,23,0.9,32.9,7.9c10.8,7.7,21.5,7.5,32.6,0.4c5.7-3.6,11.9-6.4,18.5-9.9
		C629.7,444.7,629.7,433,629.7,419.9z M418.1,265.6c-12.1-33.5-25.7-64.9-45.9-93c-7.6-10.6-15.9-20.7-27.2-27.6
		c-10.9-6.6-19.6-4-23.6,8.1c-2.9,8.7-4.7,18.1-5.4,27.3c-1.8,26,2,51.5,7.5,76.9c2.5,11.6,5.6,23.1,8.7,36
		c11-13.3,23.4-22.2,38.5-27C386,261.4,401.5,261.6,418.1,265.6z M432,260.4c9.9-11.7,22.6-20.6,37.9-25.5c15.2-4.9,30.5-4.9,46.2-1
		c-16-61.4-102.6-114.1-159-96.9c19.7,15.6,33,36,44.1,57.6C412.3,216,421.8,238.4,432,260.4z M319.1,296.9
		c-12.4-47.6-23.6-94.6-13.1-144.5c-33,18.6-55.7,44.8-68.6,79.3c-11.3,30.1-12.5,60.8-3.5,92.6
		C257.3,298.6,285.1,289.1,319.1,296.9z"
      />
      <path
        d="M579.9,269.9c21.4,0,38.8,17.3,38.8,38.7c0.1,21.4-17.7,39.2-39.1,39.1c-21.6-0.1-38.8-17.6-38.7-39.3
		C541.1,287,558.4,269.9,579.9,269.9z M605.1,308.7c0-14.1-11.4-25.5-25.3-25.5c-13.8,0-25.5,11.8-25.5,25.6
		c0,13.9,11.5,25.3,25.6,25.2C594.1,334,605.1,322.9,605.1,308.7z"
      />
      <path
        d="M524.6,315.6c-0.8-0.1-2.9,0.4-4-0.5c-2-1.6-4.4-3.7-4.8-6c-0.4-1.8,1.6-5.8,3-6c4.6-0.7,9.5-0.6,14,0.3
		c1.2,0.3,2.6,4.5,2.3,6.8C534.4,315.9,529.6,315.5,524.6,315.6z"
      />
      <path
        d="M586.1,363.1c-0.2,1.8,0.2,4.1-0.8,5.3c-1.6,2-4.1,4.4-6.2,4.4c-2.1,0-5.2-2.3-6.1-4.4c-1.1-2.7-0.8-6.2-0.5-9.3
		c0.3-3.8,2.7-6.1,6.6-6.1c4,0,6.1,2.3,6.7,6.1c0.2,1.3,0,2.7,0,4C585.9,363.1,586,363.1,586.1,363.1z"
      />
      <path
        d="M633.5,300.5c3.7,1.9,7.6,2.7,8.9,5c1.1,1.9,0.1,6.1-1.4,8.3c-2,2.9-12.7,3-14.9,0.2c-1.6-1.9-2.8-6.3-1.7-7.7
		C626.3,303.7,630.1,302.5,633.5,300.5z"
      />
      <path
        d="M585.8,255.3c0,1.3,0.2,2.7,0,4c-0.5,3.3-2.3,5.5-5.8,5.8c-3.6,0.3-6.3-1.5-7.1-4.9c-0.7-2.8-0.7-5.9-0.4-8.9
		c0.3-4,2.8-6.4,6.9-6.2c3.9,0.1,6,2.5,6.4,6.3C586,252.6,585.9,254,585.8,255.3C585.8,255.3,585.8,255.3,585.8,255.3z"
      />
      <path
        d="M626.7,348.8c0.1,3.9-4.2,8.4-7.3,6.6c-4.1-2.4-8-6.2-10.3-10.3c-1.9-3.4,2.1-7.4,6.3-7.4
		C619.4,337.8,626.6,344.8,626.7,348.8z"
      />
      <path
        d="M550.2,274.8c-0.8,0.9-2,3.4-4,4.2c-1.8,0.8-4.7,0.4-6.4-0.6c-2.5-1.5-4.7-3.8-6.2-6.3c-1-1.7-1.4-4.7-0.6-6.3
		c1-1.8,3.6-3.5,5.7-3.8C543,261.4,550.3,269.2,550.2,274.8z"
      />
      <path d="M615.5,280c-4.9,0.2-8.7-5.6-5.9-9.8c2-2.9,4.7-5.6,7.7-7.5c4.1-2.5,9.5,1.2,9.4,5.9C626.7,272.4,619.4,279.8,615.5,280z" />
      <path
        d="M550.2,345c0,4.1-7.1,11.1-11.2,11.2c-3.6,0.1-6.8-2.9-6.9-6.4c-0.1-3.9,7.6-11.7,11.6-11.6
		C547.2,338.3,550.2,341.4,550.2,345z"
      />
    </g>
  </svg>
);

export default poetto;
