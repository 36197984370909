import uova from "../imgs/allergeni/a-uova.svg";
import arachidi from "../imgs/allergeni/a-arachidi.svg";
import crostacei from "../imgs/allergeni/a-crostacei.svg";
import guscio from "../imgs/allergeni/a-guscio.svg";
import glutine from "../imgs/allergeni/a-glutine.svg";
import latte from "../imgs/allergeni/a-latte.svg";
import lupini from "../imgs/allergeni/a-lupini.svg";
import mare from "../imgs/allergeni/a-mare.svg";
import pesce from "../imgs/allergeni/a-pesce.svg";
import sedano from "../imgs/allergeni/a-sedano.svg";
import senape from "../imgs/allergeni/a-senape.svg";
import sesamo from "../imgs/allergeni/a-sesamo.svg";
import soia from "../imgs/allergeni/a-soia.svg";
import solfiti from "../imgs/allergeni/a-solfiti.svg";

const allergeniMap = {
  uova: { alt: "uova", src: uova },
  arachidi: { alt: "arachidi", src: arachidi },
  crostacei: { alt: "crostacei", src: crostacei },
  glutine: { alt: "glutine", src: glutine },
  guscio: { alt: "frutta a guscio", src: guscio },
  latte: { alt: "latte", src: latte },
  lupini: { alt: "lupini", src: lupini },
  mare: { alt: "molluschi", src: mare },
  pesce: { alt: "pesce", src: pesce },
  sedano: { alt: "sedano", src: sedano },
  senape: { alt: "senape", src: senape },
  sesamo: { alt: "sesamo", src: sesamo },
  soia: { alt: "soia", src: soia },
  solfiti: { alt: "solfiti", src: solfiti },
};

const Allergeni = ({ allergeni }) => {
  return (
    <>
      {allergeni.map(
        (a) =>
          allergeniMap[a] && (
            <img
              key={allergeniMap[a].alt}
              src={allergeniMap[a].src}
              alt={allergeniMap[a].alt}
            />
          )
      )}
    </>
  );
};

export default Allergeni;
