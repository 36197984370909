import moto from "../imgs/moto";
import sacc from "../imgs/sacc";
import poetto from "../imgs/poetto";
import mappa from "../imgs/mappa.jpeg";
import { cittaConsegna, fermatePoetto, luoghi, stabilimenti } from "../menu";
import "../styles/consegna.css";

const delOpts = [
  [12, 0],
  [12, 30],
  [13, 0],
  [13, 30],
  [14, 0],
  [14, 30],
  [19, 0],
  [19, 30],
  [20, 0],
  [20, 30],
  [21, 0],
  [21, 30],
  [22, 0],
];

const poettoHours = [
  [12, 0],
  [12, 30],
  [13, 0],
  [13, 30],
  [14, 0],
  [14, 30],
];

const InfoConsegna = ({
  setCitta,
  type,
  setType,
  isPoettoOnly,
  poettoInfo,
}) => {
  const now = new Date();
  const [chh, cmm] = [now.getHours(), now.getMinutes()];

  //const isPoettoAvailable = chh >= 10 || chh <= 14;
  const isPoettoAvailable = true;

  return (
    <>
      {!isPoettoOnly && (
        <>
          <h1>modalità di consegna</h1>

          <div className="mod-c">
            <button
              className={type === "takeaway" ? "selected" : ""}
              onClick={(e) => {
                e.preventDefault();
                if (type !== "takeaway") {
                  setCitta(null);
                  setType("takeaway");
                }
              }}
            >
              <div>{sacc}</div>
              <p>
                ritiro al
                <br />
                ristorante
              </p>
            </button>
            <button
              className={type === "consegna" ? "selected" : ""}
              onClick={(e) => {
                e.preventDefault();
                if (type !== "consegna") {
                  setCitta(cittaConsegna[0]);
                  setType("consegna");
                }
              }}
            >
              <div>{moto}</div>
              <p>
                consegna
                <br />a casa
              </p>
            </button>
            <button
              className={type === "poetto" ? "selected" : ""}
              disabled={!isPoettoAvailable}
              onClick={(e) => {
                e.preventDefault();
                if (isPoettoAvailable && type !== "poetto") {
                  setCitta(null);
                  setType("poetto");
                }
              }}
            >
              <div>{poetto}</div>
              <p>
                consegna
                <br />
                al poetto
                {/* <br />
                <span className="small">(dalle 12:30 alle 14:30)</span> */}
              </p>
            </button>
          </div>
        </>
      )}
      <input type="hidden" name="modalita-consegna" value={type} />
      <h1>
        Informazioni
        <br />
        per la consegna
      </h1>
      <div className="inp-cont">
        <label>
          <span>nome</span>
          <input required type="text" name="nome" />
        </label>
        <label>
          <span>cognome</span>
          <input required type="text" name="cognome" />
        </label>
        <label>
          <span>email</span>
          <input required type="email" name="email" />
        </label>
        <label>
          <span>telefono</span>
          <input required type="text" name="phone" />
        </label>

        {type === "consegna" && (
          <>
            <label>
              <span>indirizzo</span>
              <input required type="text" name="indirizzo" />
            </label>
            <label>
              <span>città</span>
              <select
                name="citta"
                onChange={(e) => {
                  const c = cittaConsegna.filter(
                    (c) => c.citta === e.target.value
                  )[0];
                  setCitta(c);
                }}
              >
                {cittaConsegna.map((c) => (
                  <option key={c.citta} value={c.citta}>
                    {c.citta} - € {c.prezzo.toFixed(2)}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <span>orario di consegna</span>
              <select name="ora-consegna">
                {delOpts.map(([hh, mm]) => (
                  <option
                    disabled={
                      chh > hh ||
                      (chh === hh && cmm + 15 > mm) ||
                      (chh + 1 === hh && cmm - 45 > mm)
                    }
                    key={`${hh}${mm}`}
                    value={`${hh}:${mm.toString().padStart(2, "0")} - ${
                      mm === 0 ? `${hh}:30` : `${hh + 1}:00`
                    }`}
                  >
                    {`${hh}:${mm.toString().padStart(2, "0")} - ${
                      mm === 0 ? `${hh}:30` : `${hh + 1}:00`
                    }`}
                  </option>
                ))}
              </select>
            </label>
          </>
        )}
        {type === "takeaway" && (
          <label>
            <span>orario di ritiro</span>
            <select name="ora-ritiro">
              {delOpts.map(([hh, mm]) => (
                <option
                  disabled={
                    chh > hh ||
                    (chh === hh && cmm + 15 > mm) ||
                    (chh + 1 === hh && cmm - 45 > mm)
                  }
                  key={`${hh}${mm}`}
                  value={`${hh}:${mm.toString().padStart(2, "0")} - ${
                    mm === 0 ? `${hh}:30` : `${hh + 1}:00`
                  }`}
                >
                  {`${hh}:${mm.toString().padStart(2, "0")} - ${
                    mm === 0 ? `${hh}:30` : `${hh + 1}:00`
                  }`}
                </option>
              ))}
            </select>
          </label>
        )}
        {type === "poetto" && (
          <>
            <label className="large">
              <span>orario di consegna</span>
              <select name="ora-consegna" required>
                {poettoHours.map(([hh, mm]) => (
                  <option
                    disabled={
                      (chh > hh ||
                        (chh === hh && cmm + 15 > mm) ||
                        (chh + 1 === hh && cmm - 45 > mm)) &&
                      chh < 17
                    }
                    key={`${hh}${mm}`}
                    value={`${hh}:${mm.toString().padStart(2, "0")} - ${
                      mm === 0 ? `${hh}:30` : `${hh + 1}:00`
                    }`}
                  >
                    {`${hh}:${mm.toString().padStart(2, "0")} - ${
                      mm === 0 ? `${hh}:30` : `${hh + 1}:00`
                    }`}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <span>luogo</span>
              <select
                name="luogo"
                value={poettoInfo.typePoetto}
                onChange={(e) => {
                  poettoInfo.setTypePoetto(e.target.value);
                }}
              >
                {luoghi.map((fp) => (
                  <option key={fp} value={fp}>
                    {fp}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <span>stabilimento</span>
              <select
                name="stabilimento"
                value={poettoInfo.subtypePoetto}
                onChange={(e) => {
                  poettoInfo.setSubtypePoetto(e.target.value);
                }}
              >
                <option disabled={true} value="">
                  Seleziona stabilimento
                </option>
                {stabilimenti.map((fp) => (
                  <option key={fp} value={fp}>
                    {fp}
                  </option>
                ))}
              </select>
            </label>
            {/* <img
              alt="mappa fermate poetto"
              src={mappa}
              style={{ width: "100%" }}
            /> */}
          </>
        )}
        {/* <label className="short">
          <span>cap</span>
          <input required type="text" name="cap" />
        </label> */}
        <label className="large">
          <span>note</span>
          <textarea
            name="note"
            rows="4"
            placeholder="richieste speciali per l'ordine o la consegna? scrivile qui!"
          ></textarea>
        </label>
      </div>
      <p>
        I dati forniti vengono usati esclusivamente per la consegna e non sono
        condivisi con servizi di terze parti.
      </p>
    </>
  );
};

export default InfoConsegna;
