import "../styles/checkbox.css";
import "../styles/option.css";
import Sep from "../imgs/sep.svg";
import meno from "../imgs/meno";
import piu from "../imgs/piu";

const Checkbox = ({
  title,
  extraPrice,
  subtitle,
  options,
  selected,
  handleChange,
}) => {
  return (
    <div className="checkbox-block">
      <img src={Sep} alt="" />
      <div className="tit-headers">
        <div>
          <h3>{title}</h3>
          <p className="subtit">{subtitle}</p>
          <p className="extra">{extraPrice && extraPriceString(extraPrice)}</p>
        </div>
      </div>
      <div className="opts">
        {options.map((opt, idx) => (
          <Label
            key={opt}
            sharedName={title}
            name={opt}
            qt={selected[idx]}
            add={(e) => {
              e.preventDefault();
              handleChange(idx, true);
            }}
            sub={(e) => {
              e.preventDefault();
              handleChange(idx, false);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Label = ({ sharedName, name, qt, add, sub }) => (
  <div
    style={{ padding: "20px", width: "30%", flex: "1 1 auto" }}
    className="crea-ingred"
  >
    <p style={{ marginBottom: "10px", textAlign: "center" }}>{name}</p>
    <div className="opt-qt" style={{ justifyContent: "center" }}>
      <button onClick={sub} disabled={qt === 0}>
        {meno}
      </button>
      <p>{qt}</p>
      <button onClick={add} disabled={qt === 5}>
        {piu}
      </button>
    </div>
  </div>
);

const extraPriceString = (price) => `extra + €${price.toFixed(2)}`;

export default Checkbox;
