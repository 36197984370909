import React, { useEffect, useState } from "react";
import {
  dolci,
  bevande,
  pokeSpeciali,
  pokeAltri,
  cittaConsegna,
  luoghi,
  stabilimenti,
} from "../menu";
import Pronti from "./pronti";
import Poke from "./poke";
import CreaPoke from "./crea-poke";
import InfoConsegna from "./info-consegna";
import allergeniImg from "../imgs/allergeni/a-tutti.svg";
import sacc from "../imgs/sacc";
import moto from "../imgs/moto";
import poetto from "../imgs/poetto";

const [POETTO_PARAM, POETTO_VALUE] = ["consegna", "al-poetto"];

const Form = () => {
  // menu
  const [selectedDolci, setDolci] = useState(dolci.map((d) => 0));
  const [selectedBevande, setBevande] = useState(bevande.map((d) => 0));
  const [selectedPokeSpec, setPokeSpec] = useState(pokeSpeciali.map((d) => 0));
  const [selectedPokeAltri, setPokeAltri] = useState(pokeAltri.map((d) => 0));

  // creazione poke
  const [isCreating, setIsCreating] = useState(false);
  const [customPokes, setCustomPokes] = useState([]);

  // delivery
  const [type, setType] = useState("takeaway");
  const [citta, setCitta] = useState(null);

  // poetto
  const [typePoetto, setTypePoetto] = useState(luoghi[0]);
  const [subtypePoetto, setSubtypePoetto] = useState("");

  const addCustomPoke = (customPoke) => {
    if (customPoke) {
      setCustomPokes((old) => {
        const newPokes = [...old];
        newPokes.push(customPoke);
        return newPokes;
      });
    }
    setIsCreating(false);
  };

  const [price, setPrice] = useState();

  useEffect(() => {
    let newPrice = 0;

    newPrice += customPokes.reduce((cum, nex) => cum + nex.price, 0);

    // poke pronti, dolci e bevande
    newPrice += selectedPokeSpec
      .map((qt, idx) => qt * pokeSpeciali[idx].price)
      .reduce((cum, nex) => cum + nex, 0);

    newPrice += selectedPokeAltri
      .map((qt, idx) => qt * pokeAltri[idx].price)
      .reduce((cum, nex) => cum + nex, 0);

    newPrice += selectedDolci
      .map((qt, idx) => qt * dolci[idx].price)
      .reduce((cum, nex) => cum + nex, 0);

    newPrice += selectedBevande
      .map((qt, idx) => qt * bevande[idx].price)
      .reduce((cum, nex) => cum + nex, 0);

    if (citta !== null) {
      newPrice += citta?.prezzo ?? 0;
    } else if (type === "poetto") {
      newPrice += 3.5;
    }

    setPrice(newPrice);
  }, [
    customPokes,
    selectedPokeSpec,
    selectedPokeAltri,
    selectedDolci,
    selectedBevande,
    citta,
    type,
  ]);

  const outputCustomPoke = (customPokes) =>
    customPokes.map(({ price, size }, idx) => (
      <p key={idx}>
        <span>poke {size} personalizzata</span>{" "}
        <span>{price.toFixed(2).padStart(5, "0")}€</span>
      </p>
    ));

  const outputMenu = (selected, list) =>
    list
      .map(({ name, price, spec }, i) => ({
        name,
        price,
        spec,
        qt: selected[i],
      }))
      .filter((e) => e.qt)
      .map(({ name, price, spec, qt }) => (
        <p key={spec ? name + spec : name}>
          <span>{`${name.toLowerCase()} ${(spec ?? "").toLowerCase()}`}</span>{" "}
          <span>
            {qt} x {price.toFixed(2).padStart(5, "0")}€
          </span>
        </p>
      ));

  const [isPoettoOnlyPage, setIsPoettoOnlyPage] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(document?.location?.search);
    const isPoettoOnly = params.get(POETTO_PARAM) === POETTO_VALUE;
    if (isPoettoOnly) {
      setIsPoettoOnlyPage(true);
      setType("poetto");
    }
  }, []);

  return (
    <form
      action="thankyou.php"
      method="post"
      onSubmit={(e) => {
        if (type === "poetto" && subtypePoetto === "") {
          e.preventDefault();
          return false;
        }
        const nowString = new Date().toString();
        const orarioInvioInput = document.querySelector("#orario-invio");
        orarioInvioInput.value = nowString;
        return true;
      }}
    >
      <section className="intro-maua">
        <p className="intro">
          {isPoettoOnlyPage
            ? "Consegna in spiaggia dalle 12:30 alle 14:30."
            : "Siamo aperti dalle 12:00 alle 14:30 e dalle 19:00 alle 22:30."}
          <br />
          Componi la tua poke o scegli tra le proposte dello chef
        </p>
        <div className="d-opt">
          {!isPoettoOnlyPage && (
            <>
              <div>
                {sacc}
                <h4>"Take Haway"</h4>
                <p>
                  ordina qui e ritira al locale
                  <br />
                  saltando la fila
                </p>
              </div>
              <div>
                {moto}
                <h4>"Maua Delivery"</h4>
                <p>
                  ordina qui e consegnamo noi
                  <br />
                  dove vuoi*
                </p>
              </div>
            </>
          )}
          <div>
            {poetto}
            <h4>"Al Poetto"</h4>
            <p>
              ordina qui e te lo consegnamo in spiaggia
              {/* <br />
              (solo a pranzo) */}
            </p>
          </div>
        </div>
        <p className="postilla">
          *pagamento in contanti o pos - minimo ordine € 10.00 - consegna entro
          un'ora dall'ordine a {cittaConsegna.map((c) => c.citta).join(", ")}.
        </p>
      </section>

      <section className="crea-poke">
        <div className="crea-modal">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsCreating(true);
            }}
          >
            {customPokes.length > 0 ? "Crea un'altra poke" : "Crea la tua poke"}
          </button>
          {!isCreating && <p>oppure</p>}
        </div>
        <CreaPoke isCreating={isCreating} addCustomPoke={addCustomPoke} />
      </section>
      <section className="poke-pronti">
        <h1>Ordina</h1>
        <Poke
          title="le proposte dello chef"
          options={pokeSpeciali}
          selected={selectedPokeSpec}
          setSelected={setPokeSpec}
        />
        <Poke
          title="tutti i piatti"
          options={pokeAltri}
          selected={selectedPokeAltri}
          setSelected={setPokeAltri}
        />
        <p className="trattamento">
          IL PESCE DESTINATO AD ESSERE CONSUMATO CRUDO O PRATICAMENTE CRUDO È
          STATO SOTTOPOSTO A TRATTAMENTO DI BONIFICA PREVENTIVA CONFORME ALLE
          PRESCRIZIONI DEL REGOLAMENTO (CE) 853/2004, ALLEGATO III, SEZIONE
          VIII, CAPITOLO 3, LETTERA D, PUNTO 3.
        </p>
      </section>
      <section className="dolci-e-bev">
        <Pronti
          title="dolci e frutta"
          options={dolci}
          selected={selectedDolci}
          setSelected={setDolci}
        />
        <Pronti
          title="bevande"
          options={bevande}
          selected={selectedBevande}
          setSelected={setBevande}
        />
      </section>
      <section className="allergeni">
        <h3>Allergeni</h3>
        <img src={allergeniImg} alt="icone degli allergeni con descrizione" />
      </section>
      <section className="info-consegna" id="info-consegna">
        <InfoConsegna
          setCitta={setCitta}
          type={type}
          setType={setType}
          isPoettoOnly={isPoettoOnlyPage}
          poettoInfo={{
            typePoetto,
            setTypePoetto,
            subtypePoetto,
            setSubtypePoetto,
          }}
        />
      </section>
      {price && (
        <section className="riepilogo-ordine">
          <input
            type="hidden"
            name="order"
            value={JSON.stringify({
              selectedBevande,
              selectedDolci,
              selectedPokeSpec,
              selectedPokeAltri,
              customPokes,
              price,
            })}
          />
          <input
            type="hidden"
            id="orario-invio"
            name="orario-invio"
            value="non compilato"
          />
          <h1>riepilogo ordine</h1>
          {outputCustomPoke(customPokes)}
          {outputMenu(selectedPokeSpec, pokeSpeciali)}
          {outputMenu(selectedPokeAltri, pokeAltri)}
          {outputMenu(selectedBevande, bevande)}
          {outputMenu(selectedDolci, dolci)}
          {type === "consegna" && (
            <p>
              <span>consegna a {citta.citta}</span>
              <span>{citta.prezzo.toFixed(2).padStart(5, "0")}€</span>
            </p>
          )}
          {type === "takeaway" && (
            <p>
              <span>ritiro al ristorante</span>
              <span>gratis</span>
            </p>
          )}
          {type === "poetto" && (
            <p>
              <span>
                {typePoetto} - {subtypePoetto}
              </span>
              <span>03.50€</span>
            </p>
          )}
        </section>
      )}
      <div className="price-block">
        <div>
          <a
            href="#info-consegna"
            onClick={(e) => {
              e.preventDefault();
              scrollToElement("#info-consegna");
            }}
          >
            <span>vai alle informazioni di consegna</span> ↓
          </a>
          <span>
            {price
              ? (citta !== null || type === "poetto") &&
                price - (citta?.prezzo ?? 1.5) < 10
                ? "per la consegna, ordine minimo 10€"
                : priceString(price)
              : "ordina ora"}
          </span>
        </div>
      </div>
      {price &&
        !(
          (citta !== null || type === "poetto") &&
          price - (citta?.prezzo ?? 1.5) < 10
        ) && (
          <div className="completa-acquisto">
            <button type="submit">Invia ordine</button>
          </div>
        )}
    </form>
  );
};

const priceString = (price) => `Totale ${price.toFixed(2)}€`;

const scrollToElement = (selector) => {
  const e = document.querySelector(selector);
  if (!e) return;
  window.scroll({
    top: e.getBoundingClientRect().top + window.scrollY,
    behavior: "smooth",
  });
};

export default Form;
