import meno from "../imgs/meno";
import piu from "../imgs/piu";
import "../styles/option.css";
import Allergeni from "./allergeni";

const Poke = ({ title, options, selected, setSelected }) => {
  const generateChangeQt = (idx) => {
    return (isAdding) => {
      setSelected((oldState) => {
        const newState = [...oldState];
        newState[idx] = isAdding
          ? oldState[idx] + 1
          : Math.max(0, oldState[idx] - 1);
        return newState;
      });
    };
  };

  return (
    options.length > 0 && (
      <div className="pronti-block">
        <h3>{title}</h3>
        <div>
          {options.map((opt, id) => (
            <Option
              key={opt.name}
              opt={opt}
              qt={selected[id]}
              changeQt={generateChangeQt(id)}
            />
          ))}
        </div>
      </div>
    )
  );
};

const Option = ({ opt, qt, changeQt }) => {
  return (
    <div className="opt-cont">
      <div className="opt-block poke">
        <div className="opt-info">
          <p>
            {opt.name} - {priceString(opt.price)}{" "}
          </p>
          <div className="poke-ingred">
            {opt?.ingr && <p>{opt.ingr}</p>}
            <div>
              {opt.allergeni && <Allergeni allergeni={opt.allergeni} />}
            </div>
          </div>
        </div>
        <div className="opt-qt">
          <button
            onClick={(e) => {
              e.preventDefault();
              changeQt(false);
            }}
            disabled={qt === 0}
          >
            {meno}
          </button>
          <p>{qt}</p>
          <button
            onClick={(e) => {
              e.preventDefault();
              changeQt(true);
            }}
          >
            {piu}
          </button>
        </div>
      </div>
    </div>
  );
};

const priceString = (price) => `€${price.toFixed(2)}`;

export default Poke;
