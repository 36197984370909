const bowlOpts = [
  {
    name: "small",
    title: "small",
    desc: "1 proteina",
    price: 9.9,
  },
  {
    name: "regular",
    title: "regular",
    desc: "2 proteine",
    price: 11.9,
  },
  {
    name: "large",
    title: "large",
    desc: "3 proteine",
    price: 13.9,
  },
];

const basi = [
  "RISO BIANCO",
  "RISO BASMATI",
  "RISO NERO",
  "RISO BIANCO + NERO",
  "INSALATA",
  "RISO BIANCO + INSALATA",
  "RISO BASMATI + INSALATA",
  "RISO NERO + INSALATA",
];

const verdure = [
  "ANANAS",
  "CAROTE",
  "CAVOLO ROSSO MARINATO",
  "CECI",
  "CETRIOLI",
  "CIPOLLA CARAMELLATA",
  "CIPOLLA ROSSA",
  "DAIKON MARINATO",
  "EDAMAME",
  "MAIS",
  "OLIVE",
  "POMODORINI",
  "RUCOLA",
  "WAKAME",
];
const proteine = [
  "TONNO CRUDO",
  "TONNO CRUDO MARINATO (SOIA)",
  "TONNO COTTO",
  "SALMONE CRUDO",
  "SALMONE CRUDO MARINATO (SOIA)",
  "SALMONE COTTO",
  "POLPO",
  "GAMBERO COTTO",
  "POLLO ALL'HAWAIANA",
  "CRISPY CHICKEN",
  "TOFU",
];

const salse = [
  "MAIONESE",
  "MAYO LIME",
  "SPICY MAYO",
  "YOGURT",
  "TERIYAKI",
  "AGRODOLCE",
  "SRIRACHA",
  "SOIA",
  "OLIO EVO",
  "PONZU",
];

const toppings = [
  "SESAMO",
  "ZENZERO MARINATO",
  "ARACHIDI",
  "ANACARDI",
  "MANDORLE",
  "ALGA NORI",
  "KATAIFI",
  "PISTACCHIO",
  "SEMI DI GIRASOLE",
  "SEMI DI ZUCCA",
  "CRISPY ONION",
];

const extra = [
  { name: "avocado", price: 0.5, type: "verdura" },
  { name: "mango", price: 0.5, type: "verdura" },
  { name: "mayo veg", price: 0.5, type: "salsa" },
  { name: "Avocado e Philadelphia", price: 0.5, type: "salsa" },
  { name: "philadelphia", price: 0.5, type: "salsa" },
  { name: "soia senza glutine", price: 0.5, type: "salsa" },
  { name: "gambero panko", price: 1.0, type: "proteina" },
  { name: "gambero kataifi", price: 1.0, type: "proteina" },
  { name: "gambero in crosta di patate", price: 1.0, type: "proteina" },
  //{ name: "pollo Vegano soia e sesamo", price: 1.0, type: "proteina" },
  { name: "Gambero in tempura", price: 1.0, type: "proteina" },
  { name: "vuna", price: 1.0, type: "proteina" },
];

const dolci = [
  {
    name: "mochi cream crema mango",
    price: 2,
    allergeni: [],
  },
  {
    name: "mochi cream crema cioccolato",
    price: 2,
    allergeni: [],
  },
  {
    name: "mochi cream gelato mango",
    price: 1.5,
    allergeni: [],
  },
  {
    name: "mochi cream crema cioccolato",
    price: 1.5,
    allergeni: [],
  },
  {
    name: "CHEESE CAKE ALLO YUZU",
    price: 5.9,
    allergeni: [],
  },
  {
    name: "mousse al cioccolato",
    price: 4.9,
    allergeni: ["guscio"],
  },
  {
    name: "fruit bowl",
    price: 3.9,
  },
];

const bevande = [
  { name: "ACQUA SMERALDINA", price: 1.5 },
  { name: "ACQUA SMERALDINA frizzante", price: 1.5 },
  { name: "Estathe", price: 2.5, spec: "limone" },
  { name: "Estathe", price: 2.5, spec: "pesca" },
  { name: "coca cola", price: 2.5 },
  { name: "coca cola zero", price: 2.5 },
  { name: "fanta", price: 2.5 },
  { name: "sprite", price: 2.5 },
  { name: "limonata", price: 2.5 },
  { name: "chinotto", price: 2.5 },
  { name: "Arizona Tea", price: 3.5 },
  { name: "Aloe Vera", price: 4.0 },
  { name: "BIRRA ICHNUSA", price: 2.8 },
  { name: "BIRRA ICHNUSA", price: 3.0, spec: "non filtrata" },
  { name: "BIRRE ESTERE", price: 3.0, spec: "beck's" },
  { name: "BIRRE ESTERE", price: 3.0, spec: "heineken" },
  { name: "BIRRE SPECIALI", price: 4.0, spec: "corona" },
  { name: "BIRRA HAWAIANA", price: 5.0, spec: "KONA" },
  //{ name: "BIRRA HAWAIANA", price: 5.0, spec: "HANALEI IPA" },
  //{ name: "BIRRA HAWAIANA", price: 5.0, spec: "BIG WAVE GOLDEN ALE" },
];

const pokeSpeciali = [
];

const pokeAltri = [
  {
    name: "MAUA BOWL",
    price: 14.4,
    ingr: "Riso bianco, Salmone marinato, Polpo e gamberi cotti, Avocado, Ananas, Pomodorini, Carote condite, Teriyaki, Mayo lime, Mandorle, Sesamo",
    allergeni: [
      "uova",
      "pesce",
      "soia",
      "guscio",
      "senape",
      "sesamo",
      "solfiti",
    ],
  },
  /*{
    name: "SUPER SALMON BOWL",
    price: 13.4,
    ingr: "Riso bianco, Salmone marinato, Ikura (uova di salmone), Avocado, Edamame, Wakame, Cetrioli, Teriyaki, Mayo lime, Zenzero marinato, Sesamo.",
    allergeni: [
      "crostacei",
      "uova",
      "pesce",
      "soia",
      "guscio",
      "senape",
      "sesamo",
      "solfiti",
      "molluschi"
    ],
  },*/
  {
    name: "HONOLULU BOWL",
    price: 12.9,
    ingr: "Riso bianco, Salmone e Tonno marinati, Avocado, Edamame, Mango, Cavolo rosso, Mayo lime, Soia, Zenzero marinato, Sesamo.",
    allergeni: [
      "uova",
      "pesce",
      "soia",
      "sesamo",
      "solfiti",
    ],
  },
  
  /*{
    name: "UKULELE BOWL",
    price: 12.4,
    ingr: "Riso bianco, Tofu, Avocado, Cetrioli, Pomodorini, Wakame, Teriyaki, Spicy mango, Arachidi, Menta.",
    allergeni: [
      "glutine",
      "arachidi",
      "soia",
      "solfiti",
      "sesamo",
    ],
  },*/
  {
    name: "VEGAN BOWL",
    price: 11.9,
    ingr: "Riso bianco, Vuna, Edamame, Cetrioli, Cavolo rosso, Avocado, Mayo veg, Teriyaki, Alga nori, Crispy onion.",
    allergeni: ["glutine", "arachidi", "soia", "solfiti", "sesamo"],
  },
  {
    name: "MAORI BOWL",
    price: 11.9,
    ingr: "Riso bianco, Doppio Pollo, Ananas, Edamame, Rucola, Pomodorini, Salsa agrodolce, Salsa ponzu, Mandorle, Sesamo.",
    allergeni: ["soia", "guscio", "sesamo", "solfiti"],
  },
  {
    name: "MEDITERRANEAN BOWL",
    price: 9.9,
    ingr: "Riso bianco, Tonno cotto, Pomodorini, Rucola, Olive, Cipolla rossa, Olio evo, Soia, Semi di girasole, Semi di zucca.",
    allergeni: [
      "glutine",
      "pesce",
      "soia",
    ],
  },
  {
    name: "KIDS BOWL",
    price: 7.9,
    ingr: "Riso bianco, pollo, pomodorini, mais, carote, mayo lime, arachidi.",
    allergeni: [
      "glutine",
      "pesce",
      "soia",
    ],
  },
  {
    name: "AVOCADO TOAST",
    price: 5.9,
    ingr: "Con crema di Guacamole",
    allergeni: [
      "glutine",
      "pesce",
    ],
  },
  {
    name: "BAO BUNS GAMBERO IN TEMPURA",
    price: 4.5,
    ingr: "Panino dolce",
    allergeni: [],
  },
  {
    name: "BAO BUNS CHICKEN BURGER",
    price: 4,
    ingr: "Panino dolce",
    allergeni: [],
  },
  {
    name: "BAO BUNS VEGANO",
    price: 4,
    ingr: "Panino dolce",
    allergeni: [],
  },
  {
    name: "GYOZA GAMBERO",
    price: 5.0,
    ingr: "Ravioli ripieni (4 pz)",
    allergeni: [],
  },
  {
    name: "GYOZA CON VERDURE",
    price: 5.0,
    ingr: "Ravioli ripieni (4 pz)",
    allergeni: [],
  },
  {
    name: "GYOZA POLLO E VERDURE",
    price: 5.0,
    ingr: "Ravioli ripieni (4 pz)",
    allergeni: [],
  },
  {
    name: "MUSUBI CON TONNO",
    price: 5.9,
    ingr: "Sushi tipico hawaiano con riso, alga nori, teriyaki e salsa furikake",
    allergeni: ["pesce", "soia", "sesamo", "glutine"],
  },
  {
    name: "MUSUBI CON POLLO",
    price: 5.5,
    ingr: "Sushi tipico hawaiano con riso, alga nori, teriyaki e salsa furikake",
    allergeni: ["soia", "sesamo", "glutine"],
  },
  {
    name: "MUSUBI CON TOFU",
    price: 5.5,
    ingr: "Sushi tipico hawaiano con riso, alga nori, teriyaki e salsa furikake",
    allergeni: ["soia", "sesamo", "glutine"],
  },
  {
    name: "MUSUBI CON SALMONE",
    price: 5.5,
    ingr: "Sushi tipico hawaiano con riso, alga nori, teriyaki e salsa furikake",
    allergeni: ["pesce", "soia", "sesamo", "glutine"],
  },
  {
    name: "NAMA DI POLLO",
    price: 4.9,
    ingr: "Involtino in carta di riso, pollo, lattuga, cavolo rosso e salsa kimtchi",
    allergeni: ["soia", "glutine"],
  },
  {
    name: "NAMA DI GAMBERO",
    price: 5.2,
    ingr: "Involtino in carta di riso, gambero, lattuga, ananas e salsa agrodolce all’ananas home made",
    allergeni: ["crostacei"],
  },
  {
    name: "NAMA VEGANO",
    price: 4.9,
    ingr: "Involtino in carta di riso, lattuga, carote, mango e salsa agrodolce",
    allergeni: [],
  },
  {
    name: "NAMA AL SALMONE",
    price: 5.2,
    ingr: "Involtino in carta di riso, salmone, lattuga, avocado e salsa teriyaki",
    allergeni: ["soia", "glutine"],
  },
  {
    name: "GAMBERO KATAIFI",
    price: 5.5,
    ingr: "",
    allergeni: [],
  },
  {
    name: "GAMBERO IN CROSTA DI PATATE",
    price: 5.5,
    ingr: "",
    allergeni: [],
  },
  {
    name: "GAMBERO PANKO",
    price: 5.5,
    ingr: "",
    allergeni: [],
  },
  {
    name: "GAMBERO IN TEMPURA",
    price: 5.5,
    ingr: "",
    allergeni: [],
  },
  {
    name: "CRISPY CHICKEN",
    price: 5.0,
    ingr: "",
    allergeni: [],
  },
  {
    name: "PATATINE FRITTE",
    price: 4.0,
    ingr: "",
    allergeni: [],
  },
  {
    name: "CHICKEN BURGER",
    price: 4.5,
    ingr: "",
    allergeni: [],
  },
];

const cittaConsegna = [
  { citta: "Cagliari", prezzo: 2.5 },
  { citta: "Pirri", prezzo: 3.5 },
  { citta: "Monserrato", prezzo: 3.5 },
];

const fermatePoetto = [
  "1 - pensilina fronte via Capri",
  "2 - parcheggi via Montecristo",
  "3 - pensilina fronte via vulcano",
  "4 - pensilina via Ippodromo",
  "5 - pensilina fronte via isola San Pietro",
  "6 - pensilina fronte via idrovora",
  "7 - pensilina vicino capolinea a fianco centro velico SAM",
  "8 - pensilina ultima di Cagliari",
];

const luoghi = ["Stabilimento Golfo degli Angeli"];

const stabilimenti = [
  "Golfo 2",
  "Golfo 3",
  "Golfo 4",
  "Golfo 5",
  "Golfo 6",
  "Golfo 7",
];

export {
  bowlOpts,
  basi,
  proteine,
  verdure,
  salse,
  toppings,
  extra,
  dolci,
  bevande,
  pokeSpeciali,
  pokeAltri,
  cittaConsegna,
  fermatePoetto,
  luoghi,
  stabilimenti,
};
