const piu = (
  <svg width="68" height="68" viewBox="0 0 68 68" className="aggiungi">
    <title>aggiungi</title>
    <circle cx="34" cy="34" r="29" stroke-width="9" />
    <path
      d="M23.4027 33.9997H44.9627"
      stroke-width="9"
      stroke-linecap="square"
    />
    <path d="M34 22.5L34 44.5" stroke-width="9" stroke-linecap="square" />
  </svg>
);

export default piu;
