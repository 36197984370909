import Checkbox from "./checkbox";
import Radio from "./radio";
import RadioBasic from "./radio-basic";
import {
  bowlOpts,
  basi,
  proteine,
  verdure,
  salse,
  toppings,
  extra,
} from "../menu";
import { useEffect, useState } from "react";
import Extra from "./extra";

const CreaPoke = ({ addCustomPoke, isCreating }) => {
  // creazione bowl
  const [size, setSize] = useState("regular");
  const [base, setBase] = useState();
  const [selectedProteine, setProteine] = useState(proteine.map((v) => 0));
  const [selectedVerdure, setVerdure] = useState(verdure.map((v) => 0));
  const [selectedSalse, setSalse] = useState(salse.map((v) => 0));
  const [selectedToppings, setToppings] = useState(toppings.map((v) => 0));
  const [selectedExtra, setExtra] = useState(extra.map((v) => 0));
  const [customPokePrice, setCustomPokePrice] = useState();

  const salvaPoke = (e) => {
    e.preventDefault();
    if (!customPokePrice) return;

    const customPoke = {
      size,
      base,
      selectedProteine,
      selectedVerdure,
      selectedSalse,
      selectedToppings,
      selectedExtra,
      price: customPokePrice,
    };

    addCustomPoke(customPoke);

    setBase(null);
    setProteine((old) => old.map((v) => 0));
    setVerdure((old) => old.map((v) => 0));
    setSalse((old) => old.map((v) => 0));
    setToppings((old) => old.map((v) => 0));
    setExtra((old) => old.map((v) => 0));
  };

  const annullaPoke = (e) => {
    e.preventDefault();
    addCustomPoke(null);
  };

  useEffect(() => {
    let newPrice;
    let includedProts;

    if (!base) {
      setCustomPokePrice(null);
      return;
    }

    switch (size) {
      case "small":
        newPrice = 9.9;
        includedProts = 1;
        break;
      case "regular":
        newPrice = 11.9;
        includedProts = 2;
        break;
      case "large":
        newPrice = 13.9;
        includedProts = 3;
        break;
      default:
        setCustomPokePrice(null);
        return;
    }

    const extraProts = Math.max(
      0,
      selectedProteine.reduce((cum, nex) => cum + nex, 0) +
        selectedExtra.reduce(
          (c, n, i) => c + (extra[i].type === "proteina" ? n : 0),
          0
        ) -
        includedProts
    );
    newPrice += 2 * extraProts;

    const extraVerds = Math.max(
      0,
      selectedVerdure.reduce((cum, nex) => cum + nex, 0) +
        selectedExtra.reduce(
          (c, n, i) => c + (extra[i].type === "verdura" ? n : 0),
          0
        ) -
        4
    );
    newPrice += 0.5 * extraVerds;

    const extraSalse = Math.max(
      0,
      selectedSalse.reduce((cum, nex) => cum + nex, 0) +
        selectedExtra.reduce(
          (c, n, i) => c + (extra[i].type === "salsa" ? n : 0),
          0
        ) -
        2
    );
    newPrice += 0.5 * extraSalse;

    const extraTops = Math.max(
      0,
      selectedToppings.reduce((cum, nex) => cum + nex, 0) +
        selectedExtra.reduce(
          (c, n, i) => c + (extra[i].type === "topping" ? n : 0),
          0
        ) -
        2
    );
    newPrice += 0.5 * extraTops;

    const extrasPrice = selectedExtra.reduce(
      (cum, nex, idx) => cum + nex * extra[idx].price,
      0
    );
    newPrice += extrasPrice;

    setCustomPokePrice(newPrice);
  }, [
    size,
    base,
    selectedProteine,
    selectedVerdure,
    selectedSalse,
    selectedToppings,
    selectedExtra,
  ]);

  const generateSetter = (fnc) => {
    return (idx, isPlus) => {
      fnc((old) => {
        const newState = [...old];
        newState[idx] = isPlus ? old[idx] + 1 : Math.max(0, old[idx] - 1);
        return newState;
      });
    };
  };

  let protSubtitle = "scegli max 2";
  if (size === "large") {
    protSubtitle = "scegli max 3";
  } else if (size === "small") {
    protSubtitle = "scegli max 1";
  }

  const ps = stringifyIng(selectedProteine, proteine);
  const vs = stringifyIng(selectedVerdure, verdure);
  const ss = stringifyIng(selectedSalse, salse);
  const ts = stringifyIng(selectedToppings, toppings);
  const es = stringifyIng(
    selectedExtra,
    extra.map((e) => e.name)
  );

  const riepilogo = customPokePrice
    ? `il tuo poke ${size}: ${base && base.toLowerCase()}${ps && ", " + ps}${
        vs && ", " + vs
      }${ss && ", " + ss}${ts && ", " + ts}${
        es && ", " + es
      }. Totale €${customPokePrice.toFixed(2)}`
    : "seleziona la dimensione ed una base per iniziare";

  return (
    <>
      <div
        className={isCreating ? "open-create creator-block" : "creator-block"}
      >
        <h1>Creazione Poke</h1>
        <Radio
          options={bowlOpts}
          title="Quanta fame hai?"
          name="size"
          selectedName={size}
          setName={setSize}
        />
        <RadioBasic
          title="base"
          subtitle="scegli 1"
          options={basi}
          selectedName={base}
          setName={setBase}
        />
        <Checkbox
          title="proteine"
          subtitle={protSubtitle}
          extraPrice={2}
          options={proteine}
          selected={selectedProteine}
          handleChange={generateSetter(setProteine)}
        />
        <Checkbox
          title="verdure"
          subtitle="scegli max 4"
          extraPrice={0.5}
          options={verdure}
          selected={selectedVerdure}
          handleChange={generateSetter(setVerdure)}
        />
        <Checkbox
          title="salse"
          subtitle="scegli max 2"
          extraPrice={0.5}
          options={salse}
          selected={selectedSalse}
          handleChange={generateSetter(setSalse)}
        />
        <Checkbox
          title="toppings"
          subtitle="scegli max 2"
          extraPrice={0.5}
          options={toppings}
          selected={selectedToppings}
          handleChange={generateSetter(setToppings)}
        />
        <Extra
          title="super extra"
          options={extra}
          selected={selectedExtra}
          handleChange={generateSetter(setExtra)}
        />
        <div style={{ height: "180px", background: "#f28e7c" }}></div>
      </div>
      <div className={isCreating ? "salva-block open-create" : "salva-block"}>
        <p className="riepilogo">{riepilogo}</p>
        <button className="annulla-poke" onClick={annullaPoke}>
          annulla
        </button>
        <button disabled={!customPokePrice} onClick={salvaPoke}>
          Aggiungi Poke
        </button>
      </div>
    </>
  );
};

const stringifyIng = (selected, list) => {
  console.log({ selected, list });
  return list
    .map((v, i) => `${v} x${selected[i]}`)
    .filter((v, i) => selected[i] > 0)
    .join(", ")
    .toLowerCase();
};

export default CreaPoke;
