import "../styles/checkbox.css";
import Sep from "../imgs/sep.svg";

const RadioBasic = ({ title, subtitle, options, selectedName, setName }) => {
  return (
    <div className="checkbox-block">
      <img src={Sep} alt="" />
      <div className="tit-headers">
        <div>
          <h3>{title}</h3>
          <p className="subtit">{subtitle}</p>
        </div>
      </div>
      <div className="opts">
        {options.map((opt, idx) => (
          <Label
            key={opt}
            sharedName={title}
            name={opt}
            isSelected={selectedName === opt}
            setName={setName}
          />
        ))}
      </div>
    </div>
  );
};

const Label = ({ sharedName, name, isSelected, setName }) => (
  <label className="checkbox-label">
    <input
      type="radio"
      name={sharedName}
      value={name}
      checked={isSelected}
      onChange={() => {
        setName(name);
      }}
    />
    <div className="custom-checkbox radiobasic" />
    <p>{name}</p>
  </label>
);

export default RadioBasic;
