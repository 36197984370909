const sacc = (
  <svg width="162" height="160" viewBox="0 0 162 160" className="sacc">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2425 149.359H129.479C132.041 149.359 134.442 148.56 136.523 147.122C139.245 145.204 142.768 144.085 146.61 143.925L150.132 143.766C152.374 143.766 154.615 142.807 156.056 141.049C157.497 139.291 158.138 137.054 157.978 134.816L139.565 10.6426H20.2872L4.43675 139.291C4.11654 141.848 4.75696 144.405 6.51811 146.323C8.27927 148.24 10.6808 149.359 13.2425 149.359ZM10.0404 140.09L25.4105 16.3958H134.762L152.374 135.455C152.374 136.095 152.214 136.734 151.733 137.213C151.253 137.693 150.613 138.012 149.972 138.012L146.29 138.172C141.327 138.492 136.844 139.93 133.161 142.487C132.041 143.126 130.76 143.606 129.479 143.606H13.2425C12.2819 143.606 11.4814 143.126 10.841 142.487C10.2005 141.848 9.88032 141.049 10.0404 140.09Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.2109 41.4866C87.2109 36.5324 91.2136 32.5371 96.1768 32.5371C101.14 32.5371 105.143 36.5324 105.143 41.4866C105.143 46.4407 101.14 50.436 96.1768 50.436C91.2136 50.436 87.2109 46.4407 87.2109 41.4866ZM93.1348 41.3267C93.1348 43.0847 94.5758 44.3632 96.1768 44.3632C97.938 44.3632 99.2188 43.0847 99.2188 41.3267C99.2188 39.5688 97.7779 38.2903 96.1768 38.2903C94.4157 38.2903 93.1348 39.7286 93.1348 41.3267Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.541 41.4866C42.541 36.5324 46.5436 32.5371 51.5069 32.5371C56.4702 32.5371 60.4728 36.5324 60.4728 41.4866C60.4728 46.4407 56.4702 50.436 51.5069 50.436C46.5436 50.436 42.541 46.4407 42.541 41.4866ZM48.4649 41.3267C48.4649 43.0847 49.9058 44.3632 51.5069 44.3632C53.108 44.3632 54.5489 43.0847 54.5489 41.3267C54.5489 39.5688 53.2681 38.2903 51.5069 38.2903C49.7457 38.2903 48.4649 39.7286 48.4649 41.3267Z"
    />
    <path d="M96.1761 41.486H96.016C94.415 41.486 92.974 42.7645 92.974 44.5224C92.974 45.0018 93.1341 45.3215 93.1341 45.6411V60.8232C93.1341 71.5306 84.4884 80.1604 73.7614 80.1604C63.0344 80.1604 54.3887 71.5306 54.3887 60.8232V44.3626C54.3887 42.7645 53.1078 41.3262 51.3467 41.3262C49.5855 41.3262 48.3047 42.6047 48.3047 44.3626V60.6634C48.3047 74.567 59.6721 85.9136 73.6013 85.9136C87.5304 85.9136 98.8979 74.567 98.8979 60.6634V44.3626C99.2181 42.7645 97.9373 41.486 96.1761 41.486Z" />
    <path d="M134.922 146.002H128.678L129.478 12.8789H135.242L134.922 146.002Z" />
  </svg>
);

export default sacc;
