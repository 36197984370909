import "./App.css";
import Form from "./components/form";
import logo from "./imgs/logo.webp";

function App() {
  return (
    <div className="container">
      <header></header>
      <main>
        <div className="logo">
          <img src={logo} alt="Maua Poke logo" />
        </div>
        <Form />
      </main>
    </div>
  );
}

export default App;
